import { FC, useEffect } from 'react';
import ErrorBoundary from 'containers/ErrorBoundary';
import { Outlet } from 'react-router-dom';
import authService from 'services/authService';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import bg_setting from 'assets/images/background/bg_settings.svg';

const AuthLayout: FC = ({ children }: any) => {
  const isAuth = authService.isAuthenticated();
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuth) {
      navigate('/');
    } else {
      navigate('/login');
    }
  }, [isAuth]);

  return (
    <div>
      {/* <main> */}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          padding: '10px',
        }}
      >
        <img
          src={bg_setting}
          alt=""
          style={{
            height: '100%',
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        />
        <ErrorBoundary>
          <Box
            sx={{
              position: 'relative',
              background: '#FFFFFF',
              height: '100%',
              overflow: 'auto',
            }}
          >
            <Outlet />
          </Box>
        </ErrorBoundary>
      </Box>
    </div>
  );
};

export default AuthLayout;
