// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
//
import { NavListRoot } from './NavList';
import { useAppSelector } from 'redux/hooks';
import { useEffect, useState } from 'react';
import { RootState } from 'redux/store';
import _navConfig from 'layouts/MainLayout/navbar/NavConfig';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props: any) => <ListSubheader disableSticky disableGutters {...props} />)(({ theme }: any) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

type NavSectionVerticalProps = {
  isCollapse: boolean;
  navConfig: Array<any>;
  other?: any;
};

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }: NavSectionVerticalProps) {
  const { userInfo } = useAppSelector((state: RootState) => state.global);

  const [config, setConfig] = useState([...navConfig]);

  useEffect(() => {
    const { role = [] } = userInfo || {};
    if (role[0] === 'SuperAdmin') {
      setConfig([...navConfig]);
    } else {
      setConfig([..._navConfig]);
    }
  }, [userInfo?.role]);

  return (
    <Box {...other}>
      {config.map((group: any) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          {group.items.map((list: any) => (
            <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
          ))}
        </List>
      ))}
    </Box>
  );
}
