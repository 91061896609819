import { Box, InputBase, Skeleton } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Search = styled('div')(({ theme }: any) => ({
  marginLeft: 0,
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid #E7E7E7',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  width: '600px !important',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '95%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    color: '#7A8693',
    fontSize: '16px',
    [theme.breakpoints.up('md')]: {
      // width: '20ch',
    },
  },
}));

const StaffItem = styled(Box)<any>(({ active, theme }: any) => ({
  display: 'flex',
  position: 'relative',
  minHeight: '72px',
  marginLeft: '-16px',
  marginRight: '-16px',
  borderRadius: '8px',
  backgroundColor: active ? '#EEEFF8' : 'transparent',
  padding: '0 16px',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: active ? 'interhit' : 'pointer',
  '&:hover': {
    backgroundColor: '#EEEFF8',
  },
  '& .MuiTypography-root': {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const StaffItemSekeleton = styled(Skeleton)<any>(({ active, theme }: any) => ({
  height: '72px',
  marginLeft: '-16px',
  marginRight: '-16px',
  borderRadius: '8px',
}));

export { SearchIconWrapper, Search, StyledInputBase, StaffItem, StaffItemSekeleton };
