// @mui
import { AppBar, Box, Stack, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// config
import { HEADER } from '../../../config';
// components
import SearchIcon from '@mui/icons-material/Search';
import { IconButtonAnimate } from 'components/animate';
import Iconify from '../../../components/Iconify';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import { setCurrentPage } from 'redux/slices/global';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import AccountPopover from './AccountPopover';
import NotificationPopover from './NotificationPopover';
import LanguagePopover from './LanguagePopover';
import { Search, SearchIconWrapper, StyledInputBase } from './style';

// ----------------------------------------------------------------------
const RootStyle = styled(AppBar)<any>(({ iscollapse, isoffset, verticallayout, theme }: any) => ({
  position: 'fixed',
  background: '#FFFFFF',
  // boxShadow: '0px 0px 6px 4px rgba(99, 115, 129, 0.15)',
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: '100%',
    ...(iscollapse === 'true' && {
      width: '100%',
    }),
    ...(isoffset === 'true' && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticallayout === 'true' && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

const TitleHeader = styled(Box)<any>(({ subMenu: submenu, theme }: any) => ({
  paddingLeft: 10,
  color: theme.palette.text.headline,
  fontWeight: 500,
  textAlign: 'left',
  fontSize: '24px',
  display: 'flex',
  cursor: submenu ? 'pointer' : '',
  alignItems: 'center',
}));
// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: any;
  isCollapse?: boolean;
  verticalLayout?: boolean;
  collapseClick?: any;
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }: Props) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive('up', 'lg');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { currentPage } = useAppSelector((state: RootState) => state.global);

  const { searchInput } = currentPage;
  const { haveSearch, searchText, placeholder } = searchInput || {};

  const handleSearch = (e: any) => {
    dispatch(
      setCurrentPage({
        ...currentPage,
        searchInput: {
          ...searchInput,
          searchText: e.target.value,
        },
      }),
    );
  };

  return (
    <RootStyle iscollapse={`${isCollapse}`} isoffset={`${isOffset}`} verticallayout={`${verticalLayout}`}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          paddingLeft: '0 !important',
        }}
      >
        {/* {isDesktop && <Logo />} */}
        {/* {isDesktop && <img src={richPaymentLogo} alt="" style={{ marginRight: '10px' }} />} */}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" sx={undefined} />
          </IconButtonAnimate>
        )}
        <TitleHeader
          submenu={`${currentPage.subMenu}`}
          onClick={() => {
            if (currentPage.subMenu) navigate(currentPage.backPath);
          }}
        >
          {currentPage.subMenu && <ArrowBackIosIcon />}
          {/* {currentPage.title} */}
        </TitleHeader>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {haveSearch && (
            <Search>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: '#7A7A7A' }} />
              </SearchIconWrapper>
              <StyledInputBase placeholder={placeholder || ''} inputProps={{ 'aria-label': 'search' }} value={searchText} onChange={handleSearch} />
            </Search>
          )}
          <LanguagePopover />
          <NotificationPopover />
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
