import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Avatar, Box, Divider, MenuItem, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
// routes
// hooks
import useIsMountedRef from 'hooks/useIsMountedRef';
// components
import tenantAvatarImg from 'assets/images/tenant-avatar.png';
import { IconButtonAnimate } from 'components/animate';
import MenuPopover from 'components/MenuPopover';
import sessionService from 'services/session.service';
import _authService from 'services/authService';

export default function AccountPopover() {
  const navigate = useNavigate();

  const isMountedRef = useIsMountedRef();
  const [open, setOpen] = useState(null);
  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      localStorage.removeItem('userInfo');
      sessionService.clearSession();
      _authService.clearSession();
      navigate('/login');
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const _userInfo: any = localStorage.getItem('userInfo');
  const userInfo = JSON.parse(_userInfo || '{}');
  const { username, role: _role = [] } = userInfo || {};

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...((open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme: any) => alpha(theme.palette.grey[900], 0.2),
            },
          }) as any),
        }}
      >
        {/* <MyAvatar /> */}
        <Avatar src={tenantAvatarImg} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          {
            <>
              <Typography variant="subtitle2" noWrap>
                {username}
              </Typography>
            </>
          }
        </Box>

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
