// Actions

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';

// Define a type for the slice state
interface GlobalState {
  globalSearch: string;
  currentPage: {
    title: string;
    path: string;
    subMenu: boolean;
    backPath: string;
    searchInput: {
      haveSearch: boolean;
      searchText: string;
      placeholder: string;
    };
  };

  userInfo: any;
}

// Define the initial state using that type
const initialState: GlobalState = {
  globalSearch: '',
  currentPage: {
    title: '',
    path: '/',
    subMenu: false,
    backPath: '',
    searchInput: {
      haveSearch: false,
      searchText: '',
      placeholder: 'Search...',
    },
  },
  userInfo: {},
};

export const globalSlice = createSlice({
  name: 'global',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCurrentPage: (state: GlobalState, action: PayloadAction<any>) => {
      state.currentPage = { ...action.payload };
    },

    updateUserInfo: (state: GlobalState, action: PayloadAction<any>) => {
      state.userInfo = { ...action.payload };
    },
  },
});

export const { setCurrentPage, updateUserInfo } = globalSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.counter.value;

export default globalSlice.reducer;
