import { PATH_DASHBOARD } from 'routes/paths';
import SvgIconStyle from 'components/SvgIconStyle';

// ----------------------------------------------------------------------
const getIconMenu = (name: string) => <SvgIconStyle src={`/icons/menu/${name}.svg`} />;

const ICONS = {
  home: getIconMenu('menu-ic_home'),
  transaction: getIconMenu('menu-ic_transaction'),
  ticket: getIconMenu('menu-ic_ticket'),
  payroll: getIconMenu('menu-ic_payroll'),
  settings: getIconMenu('menu-ic_settings'),
  spAccount: getIconMenu('menu-ic_spAccount'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'Merchant Management',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.home,
      },
      {
        title: 'Support Account',
        path: PATH_DASHBOARD.general.supportAccount,
        icon: ICONS.spAccount,
      },
    ],
  },
];

export default navConfig;
