import React from 'react';
import { createRoot } from 'react-dom/client';
import { store } from 'redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';

//router
import { BrowserRouter } from 'react-router-dom';

//redux
import { Provider as ReduxProvider } from 'react-redux';

//context
import { GlobalProvider } from 'contexts/GlobalContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
// import { AuthProvider } from './contexts/JWTContext';
import { ToastContainer } from 'react-toastify';

import './index.css';
import 'react-toastify/dist/ReactToastify.css';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <GlobalProvider>
        <CollapseDrawerProvider>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </CollapseDrawerProvider>
      </GlobalProvider>
    </ReduxProvider>
  </React.StrictMode>,
);

reportWebVitals();
