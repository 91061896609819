import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import navConfig from 'layouts/MainLayout/navbar/NavConfig';
import { setCurrentPage } from 'redux/slices/global';

const useChangeRoute = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const listData = handleNavConfig(navConfig);
    const currentNav = listData.filter((item) => item.path === location.pathname);
    if (!currentNav.length) return;
    dispatch(setCurrentPage(currentNav[0]));
  }, [location]);

  const handleNavConfig = (navConfig: Array<any>) => {
    const result: Array<any> = [];
    navConfig.forEach((listItems): any => {
      listItems.items.forEach((item: any) => {
        const { title, path, children } = item;
        result.push({ title, path, subMenu: false });
        if (children) {
          item.children.forEach((subItem: any) => {
            const { title, path } = subItem;
            result.push({ title, path, backPath: item.path, subMenu: true });
          });
        }
      });
    });
    return result;
  };
};

export default useChangeRoute;
