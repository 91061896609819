// @mui
import { alpha, styled } from '@mui/material/styles';
import { ListItemText, ListItemButton, ListItemIcon } from '@mui/material';
// config
import { ICON, NAVBAR } from '../../../../../config';

// ----------------------------------------------------------------------

export const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'activeroot' && prop !== 'activesub' && prop !== 'subitem' && prop !== 'iscollapse',
})(({ activeroot, activesub, subitem, iscollapse, theme }) => {
  return {
    ...theme.typography.body2,
    position: 'relative',
    height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
    color: iscollapse === 'true' ? '#FFFFFF' : theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,

    // activeRoot
    ...(activeroot === 'true' && {
      ...theme.typography.subtitle2,
      color: iscollapse === 'true' ? '#0B70E1' : theme.palette.primary.main,
      backgroundColor: iscollapse === 'true' ? '#FFFFFF' : alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      cursor: 'inherit',
      '&:hover': {
        color: iscollapse === 'true' ? '#0B70E1' : theme.palette.primary.main,
        backgroundColor: iscollapse === 'true' ? '#FFFFFF' : alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    }),
    // activeSub
    ...(activesub && {
      ...theme.typography.subtitle2,
      color: theme.palette.text.primary,
    }),
    // subItem
    ...(subitem && {
      height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT,
    }),
    '&:hover': {
      cursor: 'pointer',
    },
  };
});

export const ListItemTextStyle = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'isCollapse',
})(({ isCollapse, theme }) => ({
  whiteSpace: 'nowrap',
  transition: theme.transitions.create(['width', 'opacity'], {
    duration: theme.transitions.duration.shorter,
  }),
  ...(isCollapse && {
    width: 0,
    opacity: 0,
  }),
}));

export const ListItemIconStyle = styled(ListItemIcon)({
  width: ICON.NAVBAR_ITEM,
  height: ICON.NAVBAR_ITEM,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': { width: '100%', height: '100%' },
});
