import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import MainLayout from 'layouts/MainLayout';
import Loadable from 'layouts/Loadable';
import { PATH_DASHBOARD } from './paths';

const MerchantManagement = Loadable(lazy(() => import('features/merchant-managemant')));
const SupportAccounts = Loadable(lazy(() => import('features/support-accounts')));
const CreateUpdateMerchant = Loadable(lazy(() => import('features/merchant-managemant/CreateUpdateMerchant')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes: any = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Navigate to="/app" />,
    },
    {
      path: '/app',
      element: <MerchantManagement />,
    },
    {
      path: '/app/create-merchant',
      element: <CreateUpdateMerchant isCreate={true} />,
    },
    {
      path: '/app/update-merchant',
      element: <CreateUpdateMerchant isCreate={false} />,
    },

    {
      path: PATH_DASHBOARD.general.supportAccount,
      element: <SupportAccounts />,
    },
  ],
};

export default MainRoutes;
