import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useSettings from '../../hooks/useSettings';
// config
import { HEADER, NAVBAR } from '../../config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';

import useOffSetTop from 'hooks/useOffSetTop';
import authService from 'services/authService';

const RootStyle = styled('main')<any>(({ iscollapse, isoffset, verticallayout, theme }: any) => ({
  top: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  padding: 10,
  right: 0,
  position: 'absolute',
  // borderRadius: '16px',
  width: '100%',
  height: `calc(100vh - ${HEADER.MOBILE_HEIGHT}px)`,
  zIndex: theme.zIndex.appBar + 1,
  background: 'white',
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px - 48px)`,
    height: `calc(100vh - ${HEADER.DASHBOARD_DESKTOP_HEIGHT}px)`,
    ...(iscollapse === 'true' && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isoffset === 'true' && {}),
    ...(verticallayout === 'true' && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
  },
  [theme.breakpoints.down('lg')]: {
    top: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100%)`,
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { collapseClick, isCollapse }: any = useCollapseDrawer();

  const { themeLayout } = useSettings();

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isAuth = authService.isAuthenticated();
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuth) {
      return;
    } else {
      navigate('/login');
    }
  }, [isAuth]);
  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
        background: 'white',
      }}
    >
      {/* <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} collapseClick={collapseClick} /> */}

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <RootStyle iscollapse={`${isCollapse}`} isoffset={`${isOffset}`} verticallayout={`${verticalLayout}`}>
        <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} collapseClick={collapseClick} />
        <Outlet />
      </RootStyle>
    </Box>
  );
}
