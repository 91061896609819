import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer, Typography, SvgIcon } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// config
import { NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from './nav-section';
//
import navConfig from './NavConfig';
import NavbarAccount from './NavbarAccount';
import CollapseButton from './CollapseButton';

import richPaymentLogo from 'assets/images/rich-payment-logo.png';
import navbarTreasuresLogo from 'assets/images/icons/ic_navbar_treasures.svg';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const logoCollapseStyle = { width: '80%', height: '30px', margin: '5px 0px' };
const logoUnCollapseStyle = { width: '100px', height: '50.2px', padding: '14px 0 0 20px' };

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } = useCollapseDrawer();

  const logoStyle = isCollapse ? logoCollapseStyle : logoUnCollapseStyle;

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        '& .simplebar-content-wrapper': { overflow: 'hidden !important' },
        background: '#ffffff',
        transition: 'background 1.5s',
      }}
    >
      <img src={richPaymentLogo} alt="rich-payment-logo" style={{ width: '100px', height: '50.2px', padding: '14px 0 0 20px' }} />

      {/* {isCollapse && (
        <Stack direction="row" alignItems="center" justifyContent="center">
          <img src={richPaymentLogo} alt="rich-payment-logo" style={{ width: '80%', height: '30px', margin: '5px 0px' }} />
        </Stack>
      )}

      {!isCollapse && <img src={richPaymentLogo} alt="rich-payment-logo" style={{ width: '100px', height: '50.2px', padding: '14px 0 0 20px' }} />} */}
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between"></Stack>
        <Box sx={{ height: '50px' }} />
      </Stack>
      {/* {!isCollapse && ( */}
      <Box
        sx={{
          paddingLeft: '8px',
          marginBottom: '8px',
        }}
      >
        <Typography
          sx={{
            font: 'Public Sans',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '12px',
            lineHeight: '18px',
            color: '#061340',
            paddingLeft: '16px',
            textTransform: 'uppercase',
          }}
        >
          Management
        </Typography>
      </Box>
      {/* )} */}

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />

      <Stack direction="row" alignItems="center" justifyContent="center" sx={{ position: 'absolute', bottom: '30px', left: '28px' }}>
        <img src={navbarTreasuresLogo} alt="navbar-treasures-logo" />
      </Stack>
      {/* {!isCollapse && (
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ position: 'absolute', bottom: '30px', left: '28px' }}>
          <img src={navbarTreasuresLogo} alt="navbar-treasures-logo" />
        </Stack>
      )} */}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}
      {isDesktop && (
        <Drawer variant="permanent" open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {/* {isDesktop && (
        <Drawer
          open
          // variant="persistent"
          variant="permanent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              // position: "inherit",
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )} */}
    </RootStyle>
  );
}
