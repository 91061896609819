import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// import { fetchBusinessInfo } from 'redux/slices/global';

// @mui
import { Avatar, Box, Divider, MenuItem, Stack, Typography, Badge, SvgIcon } from '@mui/material';
import { BadgeProps } from '@mui/material/Badge';
import { alpha, styled } from '@mui/material/styles';
// routes
// hooks
import useIsMountedRef from 'hooks/useIsMountedRef';
// components
import { IconButtonAnimate } from 'components/animate';
import MenuPopover from 'components/MenuPopover';
import MyAvatar from 'components/MyAvatar';
import sessionService from 'services/session.service';
import avatarImg from 'assets/images/avatar.png';
import notificationIcon from 'assets/images/notification-icon.png';
import { dispatch, RootState, useAppDispatch, useAppSelector } from 'redux/store';
import NotificationsIcon from '@mui/icons-material/Notifications';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [];

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 8,
    top: 2,
    // fontSize: 5,
    fontSize: 10,
    height: 18,
    minWidth: 18,
    // border: `2px solid ${theme.palette.background.paper}`,
    // padding: '0 4px',
  },
}));

// ----------------------------------------------------------------------

export default function NotificationPopover() {
  const { businessInfoStatus, businessInfo } = useAppSelector((state: RootState) => state.global);
  const navigate = useNavigate();

  const isMountedRef = useIsMountedRef();
  const [open, setOpen] = useState(null);
  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      localStorage.removeItem('userInfo');
      sessionService.clearSession();
      navigate('/login');
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   if (businessInfoStatus === "failed") {
  //     dispatch(fetchBusinessInfo());
  //   }
  // }, [businessInfoStatus])

  return (
    <>
      <IconButtonAnimate
        onClick={() => {}}
        sx={{
          p: 0,
          ...((open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme: any) => alpha(theme.palette.grey[900], 0.2),
            },
          }) as any),
        }}
      >
        {/* <MyAvatar /> */}
        <StyledBadge badgeContent={4} color="error" style={{ top: '-50' }}>
          {/* <NotificationsIcon color="action" /> */}
          <SvgIcon style={{ fontSize: 30 }}>
            <path d="M15.6001 11.1666L17.1001 12.6749C17.4853 13.0665 17.5985 13.6507 17.3877 14.1579C17.1768 14.6652 16.6827 14.9968 16.1334 14.9999H13.3334V15.2832C13.2523 17.0446 11.7617 18.4086 10.0001 18.3332C8.23841 18.4086 6.74777 17.0446 6.66672 15.2832V14.9999H3.86672C3.31742 14.9968 2.82334 14.6652 2.61246 14.1579C2.40157 13.6507 2.51486 13.0665 2.90006 12.6749L4.40006 11.1666V7.2749C4.40301 5.65921 5.10159 4.12304 6.31733 3.05887C7.53307 1.9947 9.14819 1.50562 10.7501 1.71657C13.5713 2.14898 15.6413 4.59602 15.6001 7.4499V11.1666ZM10.0001 16.6666C10.8362 16.7256 11.5707 16.116 11.6667 15.2832V14.9999H8.33339V15.2832C8.4294 16.116 9.16389 16.7256 10.0001 16.6666Z" />
          </SvgIcon>
          {/* <Avatar src={notificationIcon} /> */}
        </StyledBadge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          {
            <>
              <Typography variant="subtitle2" noWrap>
                Admin
              </Typography>
            </>
          }
        </Box>

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
